<template>
    <div v-if="linkPdf != false">        
        <b-container  class="mt-3" style="margin-bottom:50px">            
            <pdf ref="myPdfComponent" v-for="i in numPages" :id="`Page-${i}`" :key="i" :src="src" :page="i" :style="`margin-top: 25px;width:${width};height:${height};`"></pdf>            
        </b-container>
        <b-modal id="modalField" ref="modalField" hide-footer title="Mot clef" size="lg" centered>
            <form>
                <b-row>                                        
                    <b-col md="6">
                        <b-form-group label="X">
                            <b-form-input v-model.number="tmp.x"></b-form-input>                            
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label="Y">
                            <b-form-input v-model.number="tmp.y"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group label="Cle">
                            <b-form-input v-model.number="tmp.key"></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="4">
                        <b-button variant="outline-primary" block @click="$refs.modalField.hide()">Fermer</b-button>
                    </b-col>
                    <b-col offset-md="4" md="4">
                        <b-button variant="primary" block @click="addField()">Sauvegarder</b-button>
                    </b-col>
                </b-row>
            </form>
        </b-modal>             
    </div>
</template>
<script>    
    import pdf from 'vue-pdf'    
    export default {
        name: "File",
        components: {
            pdf,
            //InputForm            
        },
        computed: {},
        data() {
            return {         
                linkPdf: false,
                width: "210mm",
                height: "297mm",                
                src: null,
			    numPages: undefined,
                fields: [],
                tmp: {
                    x:null,
                    y:null,
                    key:null
                }          
            }
        },
        mounted(){                                    
            this.linkPdf = this.$api.url.api+'/test/test?file='+this.$route.query.q;                        
            this.src = pdf.createLoadingTask(this.linkPdf);                    
            this.src.promise.then(pdf => {
                this.numPages = pdf.numPages;
                setTimeout(() => {
                    for(var i = 0; i < this.numPages; i ++){
                        const index = i + 1;
                        var div = document.getElementById("Page-"+index);                        
                        div.addEventListener("click", (e) => {
                            var x = e.clientX - div.offsetLeft;
                            var y = e.clientY - div.offsetTop;
                            this.tmp.x = x * 0.2645833333;
                            this.tmp.y = y * 0.2645833333;
                            this.$refs.modalField.show();                            
                        })
                    }
                },500);
            });
        },                
        methods: {            
            addField(){
                var data = JSON.parse(JSON.stringify(this.tmp));
                this.fields.push(data);
                // var json = JSON.stringify(this.fields);
                // console.log(json);
                this.$refs.modalField.hide();
                this.tmp = {
                    x:null,
                    y:null,
                    key:null
                }  
            }
        },        
    }        
</script>
<style lang="scss">    
    .logo {
        max-width: 250px;
        
    }        
    #heade-action {
        display: flex;
    }
    @media (max-width: 768px){
        #heade-action {
            display: block;
            text-align: center;
        } 
        #heade-action div {
            margin-bottom: 10px;
        }
        #heade-action .btn {
            padding: 0.50rem 0.70rem;
            font-size: 0.925rem;
            line-height: 1.35;
            border-radius: 0.85rem;
        }                
    }            
</style>